import React, {memo} from 'react';
import {graphql, Link} from 'gatsby';

import {Trans} from 'gatsby-plugin-react-i18next';
import {useTranslation} from "react-i18next";

import PageWrapper from '../../containers/PageWrapper';
import ContactForm from '../../modules/ContactForm';
import ImageSection from '../../components/ImageSection';
import Section from '../../components/Section';
import UnderlineHeading from '../../components/UnderlineHeading';
import SectionDescription from '../../components/SectionDescription';
import HorizontalCards from '../../components/HorizontalCards';
import SmallCards from '../../components/SmallCards';

const Intranets = ({data}) => {
  const {t} = useTranslation();

  return (
    <PageWrapper
      title="Intranets"
      description="Every company wants to make their staff as efficient and productive as possible. Make information flow better, processes streamlined and clear, and instant access to knowledge base. CoderyLabs uses Liferay platform to this come true."
      urlPath="solutions/intranets"
      keywords={['coderylabs', 'intranets']}
    >
      <ImageSection fluid={data.mainPhoto.childImageSharp.fluid}/>

      <Section>
        <UnderlineHeading><Trans>Modern Intranet</Trans></UnderlineHeading>
        <SectionDescription>
          <Trans>Modern Intranet Desc</Trans>
        </SectionDescription>
      </Section>

      <Section theme="dark">
        <UnderlineHeading><Trans>Features</Trans></UnderlineHeading>
        <SectionDescription style={{marginBottom: '2rem'}}>
          <Trans>Intranet Features desc</Trans>
        </SectionDescription>

        <SmallCards
          items={[
            {
              fluid: data.flexibleWorkflows.childImageSharp.fluid,
              heading: <Trans>Flexible Workflows</Trans>,
              description: <Trans>Flexible Workflows desc</Trans>,
            },
            {
              fluid: data.socialTools.childImageSharp.fluid,
              heading: <Trans>Social Tools</Trans>,
              description: <Trans>Social Tools desc</Trans>,
            },
          ]}
        />
        <SmallCards
          items={[
            {
              fluid: data.singleSignOn.childImageSharp.fluid,
              heading: <Trans>Single Sign-On</Trans>,
              description: <Trans>Single Sign-On desc</Trans>,
            },
            {
              fluid: data.teamSpaces.childImageSharp.fluid,
              heading: <Trans>Team Spaces</Trans>,
              description: <Trans>Team Spaces desc</Trans>,
            },
          ]}
        />
        <SmallCards
          items={[
            {
              fluid: data.personalStorage.childImageSharp.fluid,
              heading: <Trans>Personal Storage</Trans>,
              description: <Trans>Personal Storage desc</Trans>,
            },
            {
              fluid: data.instantMessaging.childImageSharp.fluid,
              heading: <Trans>Instant Messaging</Trans>,
              description: <Trans>Instant Messaging desc</Trans>,
            },
          ]}
        />
        <SmallCards
          items={[
            {
              fluid: data.advancedSearch.childImageSharp.fluid,
              heading: <Trans>Advanced Search</Trans>,
              description: <Trans>Advanced Search desc</Trans>,
            },
            {
              fluid: data.orgchart.childImageSharp.fluid,
              heading: <Trans>Orgchart</Trans>,
              description: <Trans>Orgchart desc</Trans>,
            },
          ]}
        />
      </Section>

      <Section>
        <UnderlineHeading><Trans>Benefits</Trans></UnderlineHeading>
        <SectionDescription><Trans>Benefits desc</Trans></SectionDescription>

        <HorizontalCards
          theme="dark"
          items={[
            {
              fluid: data.rapidInformationAccess.childImageSharp.fluid,
              heading: <Trans>Rapid information access</Trans>,
              description: <Trans>Rapid information access desc</Trans>,
            },
            {
              fluid: data.increasedStaffProductivity.childImageSharp.fluid,
              heading: <Trans>Increased staff productivity</Trans>,
              description: <Trans>Increased staff productivity desc</Trans>,
            },
            {
              fluid: data.contentCreationAndSegmentation.childImageSharp.fluid,
              heading: <Trans>Content creation and segmentation</Trans>,
              description: <Trans>Content creation and segmentation desc</Trans>,
            },
            {
              fluid: data.flexibility.childImageSharp.fluid,
              heading: <Trans>Flexibility</Trans>,
              description: <Trans>Flexibility desc</Trans>,
            },
          ]}
        />
      </Section>

      <Section theme="dark">
        <UnderlineHeading><Trans>Articles</Trans></UnderlineHeading>
        <SectionDescription>
          <Trans>Modern Intranet Article Summary</Trans>
          <Link to="/articles/modern-intranet" title={t('Read more')}>
            <Trans>Read more</Trans>
          </Link>
        </SectionDescription>
      </Section>

      <Section>
        <UnderlineHeading><Trans>Contact Us</Trans></UnderlineHeading>
        <SectionDescription>
          <Trans>Contact Us Hint</Trans>
        </SectionDescription>
        <ContactForm/>
      </Section>
    </PageWrapper>
  )
};

export default memo(Intranets);

export const query = graphql`
  query {
    mainPhoto: file(relativePath: { eq: "solutions/intranets/main_photo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rapidInformationAccess: file(relativePath: { eq: "solutions/intranets/cards/rapid_information_access.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    increasedStaffProductivity: file(relativePath: { eq: "solutions/intranets/cards/increased_staff_productivity.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    contentCreationAndSegmentation: file(relativePath: { eq: "solutions/intranets/cards/content_creation_and_segmentation.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    flexibility: file(relativePath: { eq: "solutions/intranets/cards/flexibility.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    flexibleWorkflows: file(relativePath: { eq: "solutions/intranets/cards/flexible_workflows.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    singleSignOn: file(relativePath: { eq: "solutions/intranets/cards/single_sign-on.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    personalStorage: file(relativePath: { eq: "solutions/intranets/cards/personal_storage.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    advancedSearch: file(relativePath: { eq: "solutions/intranets/cards/advanced_search.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    socialTools: file(relativePath: { eq: "solutions/intranets/cards/social_tools.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    teamSpaces: file(relativePath: { eq: "solutions/intranets/cards/team_spaces.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    instantMessaging: file(relativePath: { eq: "solutions/intranets/cards/instant_messaging.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    orgchart: file(relativePath: { eq: "solutions/intranets/cards/orgchart.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
